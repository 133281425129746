import React from "react"
import FotoCulqui from "@components/pageCasosdeExito/image/imgPhotoEmpresa/mornese/FotoMornese.png"
import FotoCulqui2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/mornese/FotoMornese2.png"

//logo de empresas clientes
import Culqui from "@components/pageCasosdeExito/image/logoscustomer/Mornese.svg"

//iconos de modulos
import crm from "@components/pageCasosdeExito/image/iconcards/crm.svg"
import blending from "@components/pageCasosdeExito/image/iconcards/blending.svg"
import integracion from "@components/pageCasosdeExito/image/iconcards/integraciones.svg"
import prediccion from"@components/pageCasosdeExito/image/iconcards/prediccion.svg"
import whatsapp from"@components/pageCasosdeExito/image/iconcards/whatsapp.svg"
import ivr from"@components/pageCasosdeExito/image/iconcards/ivrmarron.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/mornese/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/mornese/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/mornese/img3.svg"

const text1 = (
  <span className="">
 Mornese es un holding de capitales argentinos experto en cobranzas a nivel mundial. Cuenta con 20 años de experiencia brindando servicios de cobros tercerizados, principalmente para entidades financieras, en Latam y Europa.
 <br /> <br />
Desde hace 5 años, Beex optimiza las operaciones outbound e inbound de Mornese para sus más de 400 usuarios de Contact Center. Se ha logrado aumentar en +47% su contactabilidad, haciendo 3 veces más rápido y eficiente el barrido de sus bases.
  </span>
)

const text2 = (
  <span>
   Mornese, grupo experto en cobranzas brinda servicios para diferentes organizaciones en los principales países de Latinoamérica y Europa desde hace 20 años.
   <br />
    <br />
Actualmente, Mornese cuenta con operaciones de más de 400 usuarios en diferentes locaciones a nivel mundial, brindando servicios de cobros y recobros principalmente a entidades financieras.
<br />
    <br />
El grupo se especializa en brindar servicios de cobros apoyados de tecnología que mejora los objetivos de diferentes tipos de campañas, como tempranas, intermedias y tardías/castigo. Apoyándose siempre en el análisis de datos como uno de sus pilares para su toma de decisiones.
<br />
    <br />
Noelia Molina, gerente de planeamiento y estrategias, ha sido una de las responsables de la correcta curva evolutiva que ha tenido Beex, nuestra plataforma en la nube para Contact Centers, en las operaciones de Mornese.
  </span>
)

const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    <span style={{ zIndex: "1", position: "sticky" }}>
      {" "}
      “Desde el 2020 migramos nuestras operaciones globales a la nube con Beex
    </span>
    <span
      style={{
        color: "transparent",
        display: "inline",
        left: "0px",
        position: "absolute",
        top: "-2px",
      }}>“Desde el 2020 migramos nuestras operaciones globales a la nube con<span
        style={{
          boxShadow: " 0 -14px 0 #f7d731 inset",
        }}
      > Beex </span>
    </span> y fue una decisión que nos permitió darnos cuenta del poder del teletrabajo. Podemos controlar nuestra operación gracias a sus módulos de monitoreo en tiempo real, logrando mejorar nuestra tasa de ocupación en +35%.”
   </p>
)

const text4 = (
  <span>
    Desde el principio, se tenían objetivos claros, que Beex logre contactar mejor y más rápido a sus clientes de diferentes países, y que estos resultados se vean reflejados transparentemente en sus módulos internos de CRM y que sea 100% integrable a las diferentes plataformas que maneja el grupo.
    <br />
    <br />
Para esto, se llevaron etapas de implementación, que iniciaron desde la puesta en producción de campañas de solo 20 agentes, hasta llegar a la implementación total en todas sus operaciones a nivel global.
  </span>
)

const text6 = (
  <span>
 Como empresa global, sus más de 400 operadores están distribuidos en diferentes localidades de Latam y Beex permite que todos se conecten desde cualquier tipo de dispositivo, brindando visualización total de sus operaciones en tiempo real.
 <br /> <br />
Gracias a los módulos de supervisión y monitoreo de Beex, Mornese ha podido tomar decisiones que han logrado mejorar sus ratios de conexión, como su tasa de ocupación en +35%.
<br /> <br />
Además, Mornese es capaz de administrar y controlar los tiempos de gestión de sus negocios. Por ejemplo, temporizando los tiempos más importantes para cumplir con las tasas máximas de tiempo muerto o improductivo. 
  </span>
)

const text7 = (
  <span>
Al trabajar con entidades financieras como bancos, Mornese necesitaba una plataforma que pudiera integrarse no solo a sus sistemas internos, como CRM o ERP, sino también a los endpoints de sus clientes externos para compartir datos en tiempo real.
<br /> <br />
Beex, gracias a su potente estructura de APIs y webservices, permitió esto y logró integraciones y automatizaciones nuevas en el mejor tiempo posible.
<br /> <br />
Mornese es capaz de generar y enviar datos en tiempo real a diferentes endpoints sin mayor esfuerzo. Todo esto, de la mano con el equipo de soporte al cliente que siempre está disponible para cualquier cambio en las estructuras ya implementadas.
<br /> <br />
Además, Mornese, al manejar negocios de diferentes categorías, trabaja con diferentes proveedores de múltiples servicios, y Beex ha sido capaz de integrarse con ellos para diferentes fines, como: integraciones de proveedores de comunicaciones como SMS o correos, integraciones con dataleaks y repositorios de datos, integraciones con CRM, etc.
<br /> <br />
Y no solo ello, Beex, como plataforma de Contact Centers, cuenta con módulos de CRM donde existen más de 30 reportes por tipo de operación, y es algo que en Mornese sacan provecho porque tienen datos y obtienen KPIs nativos siempre que lo necesitan, en tiempo real.

  </span>
)
const text8 = (
  <span>
 “Las integraciones que hemos ganado con Beex a través de sus APIs es ideal para nuestro modelo de negocio. Podemos integrarnos fácilmente con otros proveedores, como SMS o correos, o con plataformas de Data Minning, lo cuál es algo fundamental para nuestra área de estrategias.”
  </span>
)
const text9 = (
  <span>
    Sin duda, como empresa especialista en cobranzas, uno de los puntos más importantes son las marcaciones telefónicas. El marcador predictivo con Machine Learning de Beex, ha ido evolucionando junto con la operación de Mornese, superando los objetivos planteados.
    <br /> <br />
Además, un punto importante es que, al brindar servicios a diferentes destinos del mundo, el algoritmo de detección de voz del marcador predictivo debía funcionar igual de bien para todos los destinos, es decir, manteniendo siempre su +94% de efectividad. Objetivo siempre cumplido.
<br /> <br />
Junto con los marcadores inteligentes de la plataforma, se ha sacado provecho a los módulos de IVRs entrantes y salientes de Beex. Funcionalidad que ha permitido a sus operaciones mejorar sus tiempos de sus gestiones blending.
<br /> <br />
Mornese es capaz de diseñar y poner en producción diferentes IVR entrantes para cualquier cantidad de negocios activos. Además, armar y lanzar campañas IVR salientes con retorno hacia sus teleoperadores en formato blending.
  </span>
)
const text10 = (
  <span>
    “El marcador predictivo de Beex se ha implementado con éxito para todos los destinos a los cuáles brindamos servicios en Latam y EU. Hemos mejorado nuestra contactabilidad global en +47%, lo que nos ha permitido barrer nuestras estrategias 3 veces más rápido de manera eficiente.”
  </span>
)
const text11 = (
<p>
Como menciona Noelia, la estabilidad que brinda Beex como plataforma para Contact Centers es formidable, brindando una continuidad al negocio que no habían experimentado antes, pero cada que han necesitado del acompañamiento y la ayuda de nuestro equipo de Service Desk, se ha conseguido la respuesta esperada.
<br /> <br />
Al manejar operaciones en diferentes partes del mundo, los horarios de trabajo de Mornese son variables y esta fue una característica con la que Beex Service Desk se comprometió desde el inicio.
<br /> <br />
Como parte de nuestro marco ágil de operaciones, logramos brindar un proceso de soporte formidable, acompañado de un potente SLA. Esto se ve reflejado en la experiencia brindada y Mornese es un claro ejemplo de ello. Como lo menciona Noelia, no es el típico soporte, vamos más allá.
  </p>
)

const text12 = (
  <span>
    “El soporte de Beex es increíble. Se han adaptado no solo a nuestros horarios, sino que andan atentos más allá de eso. No es el típico soporte vía tickets, contamos con grupos de WhatsApp para situaciones urgentes o consultas cotidianas, las cuáles son rápidamente atendidas.”
  </span>
)

const text13 = (
  <p>
   Estos son algunos de los procesos con los que ayudamos a Mornese en su operación global de cobranzas:
  </p>
)

const text14 = (
  <p>
    Hemos formado una sinergia importante con Mornese debido al tipo de gestión que realizan en sus operaciones, dinámicas y potentes, apoyándose mucho en la tecnología, por lo que Beex se ha convertido en un aliado importante en sus planes.
  </p>
)

const data = {
  intro: {
    image: Culqui,
    link: "https://www.youtube.com/watch?v=zVZpcYTCM7M",
    classlogo: "logo__clientes--culqui",
    text1: text1,
    text2: text2,
    text3: text3,
    fotoone: FotoCulqui,
    text4: text4,
    title6: "Contact Center global en la nube",
    text6: text6,
    title7: "Integraciones y data en tiempo real",
    text7: text7,
    fototwo: FotoCulqui2,
    text8: text8,
    title9: "Marcación Predictiva con Machine Learning e IVR",
    text9: text9,
    text10: text10,
    title11: "Acompañamiento total para diferentes horarios",
    text11: text11,
    text12: text12,
    text13: text13,
    text14: text14,
    
    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "CRM",
      img: crm,
      info:
        "Administración total de campañas de diferentes tramos, desde tempranas hasta castigadas. Cuentan con todo el detalle de sus clientes que pueden ser multicarteras y sus productos.",
    },
    {
      title: "Blending",
      img: blending,
      info:
        "Atención de clientes por canal telefónico, asegurando un adecuado nivel de servicio, además, aplicando blending a sus campañas de recupero para agilizar y optimizar el trabajo diario de sus teleoperadores.",
    },
    {
      title: "Integraciones",
      img: integracion,
      info:
        "Implementación de procesos automáticos, como reportes, envíos de información a clientes, data lakes compartidos, integración mediante APIs y más.",
    },
    {
      title: "Predicción",
      img: prediccion,
      info:
        "Marcaciones automáticas y predictivas con destinos en Latam y Europa, asegurando el porcentaje mas alto de éxito en detección de teléfonos apagados o inexistentes para diferentes países.",
    },
    {
      title: "IVR",
      img: ivr,
      info:
        "Gestión y envío de campañas IVR, informativas y con selección DTMF para devolución de contacto a teleoperadores en tiempo real.",
    },
    {
      title: "WhatsApp",
      img: whatsapp,
      info:
        "Atención al cliente vía WhatsApp Business API, usando este canal para ayudar a sus clientes con las dudas o consultas que puedan tener de sus negociaciones.",
    },
  ],
}

export default data

